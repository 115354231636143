import React from "react";
import styles from "./Notification.module.scss";

import UIStore from "../../store/UIStore";

const Notification = () => {
  const notificationMessage = UIStore((state) => state.notificationMessage);
  return (
    <div
      className={`${styles.notification} ${
        notificationMessage ? styles.active : null
      }`}
    >
      {notificationMessage}
    </div>
  );
};

export default Notification;
