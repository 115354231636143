import React, {useRef} from "react";
import {useHistory} from "react-router-dom";

import styles from "./BuildIDInput.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPager, faArrowRight} from "@fortawesome/free-solid-svg-icons";

import contentStore from "../../store/contentStore";
import UIStore from "../../store/UIStore";

const BuildIDInput = () => {
  const setBuildID = contentStore((state) => state.setBuildID);
  const notificationMessage = UIStore((state) => state.notificationMessage);
  const setNotificationMessage = UIStore(
    (state) => state.setNotificationMessage
  );
  const inputEl = useRef(null);
  const history = useHistory();

  const inputSubmitHandler = (e) => {
    if (e.key === "Enter") {
      nextClickHandler();
    }
  };
  const nextClickHandler = (e) => {
    const inputBuildID = inputEl.current.value;
    if (inputBuildID === "" || inputBuildID.length !== 6) {
      setNotificationMessage("Build ID is not valid");
    } else {
      setBuildID(inputBuildID);
      history.push("/" + inputBuildID);
    }
  };
  return (
    <div
      className={`${styles.buildIDWrapper} ${
        notificationMessage !== "" ? styles.invalid : null
      }`}
    >
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faPager} />
      </div>
      <div className={styles.searchBar}>
        <input
          ref={inputEl}
          type='text'
          placeholder='Please enter the Build ID of your creative.'
          onKeyPress={inputSubmitHandler}
        />
      </div>
      <div className={styles.next} onClick={nextClickHandler}>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </div>
  );
};

export default BuildIDInput;
