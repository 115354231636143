import React from "react";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";
import firebase from "../../core/firestore";

import userStore from "../../store/userStore";

import styles from "./LoginScreen.module.scss";

const LoginScreen = () => {
  const userInfo = userStore((state) => state.userInfo);
  // const setUserInfo = UIStore((state) => state.setUserInfo);

  const firebaseUIConfig = {
    // popup / redirect
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: () => false
    },
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };
  const renderLoginForm = () => {
    if (userInfo.loggedIn === false) {
      return (
        <div className={styles.loginScreen}>
          <div className={styles.loginWrapper}>
            <h1>Please Login</h1>
            <p>
              Login with your PLAYGROUND XYZ GAPPS Account to access the
              Creative CMS.
            </p>
            <FirebaseAuth
              uiConfig={firebaseUIConfig}
              firebaseAuth={firebase.auth()}
            />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return renderLoginForm();
};

export default LoginScreen;
