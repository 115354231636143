import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCpIzNaCyHyIeAv1iqaWOsBGsD77s3gZAw',
  authDomain: 'pxyz-creative-cms.firebaseapp.com',
  projectId: 'pxyz-creative-cms',
  storageBucket: 'pxyz-creative-cms.appspot.com',
  messagingSenderId: '52375855665',
  appId: '1:52375855665:web:5237d88faf2a28fad8eb10'
};

firebase.initializeApp(firebaseConfig);
export default firebase;
