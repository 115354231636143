
const API_URL = 'https://ads.playground.xyz/api';

export const purgeBuildCache = async (buildID) => {
  try {
    const res = await fetch(`${API_URL}/cms/build/${buildID}/purge`);
    const data = await res.json();
    return data?.success ?? false;
  } catch (err) {
    console.log(err);
    return false;
  }
};
