import React from "react";
import styles from "./Admin.module.scss";
import firebase from "../../core/firestore";

import contentStore from "../../store/contentStore";
import userStore from "../../store/userStore";
import UIStore from "../../store/UIStore";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";

const Admin = () => {
  const externalUsers = userStore((state) => state.externalUsers);
  const addEmptyNewUser = userStore((state) => state.addEmptyNewUser);
  const updateUser = userStore((state) => state.updateUser);
  const removeUser = userStore((state) => state.removeUser);
  const setNotificationMessage = UIStore(
    (state) => state.setNotificationMessage
  );
  const buildID = contentStore((state) => state.buildID);
  const newFieldHandler = () => {
    addEmptyNewUser();
  };

  const changeHandler = (e, fieldIndex) => {
    const value = e.target.value;
    updateUser(fieldIndex, value);
  };
  const removeHandler = (index) => {
    removeUser(index);
  };

  const validateEmailAddress = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateAllUserAddress = () => {
    const validatedUsers = externalUsers.filter((user) => {
      return validateEmailAddress(user);
    });
    return validatedUsers.length === externalUsers.length ? true : false;
  };
  const saveHandler = () => {
    const validatedUsers = externalUsers.map((user, i) => {
      if (validateEmailAddress(user)) {
        return user;
      } else {
        return null;
      }
    });
    if (validatedUsers.includes(null)) {
      console.log(
        "At least one of the email addresses is not valid. Please double check."
      );
    } else {
      const db = firebase.firestore();
      const dataRef = db.collection("Creatives").doc(buildID);
      dataRef.set({externalUsers: externalUsers}, {merge: true}).then(() => {
        setNotificationMessage("User successfully updated!");
      });
    }
  };
  // useEffect(() => {});
  return (
    <div className={styles.admin}>
      <h1>External Users</h1>
      <div className={styles.userList}>
        {externalUsers.map((user, i) => {
          return (
            <div
              className={`${styles.user}  ${
                validateEmailAddress(user) ? null : styles.invalid
              }`}
              key={i}
            >
              <label>
                <p>Email</p>
                <input
                  type='text'
                  value={user}
                  onChange={(e) => changeHandler(e, i)}
                ></input>
                <div
                  className={styles.delete}
                  onClick={() => {
                    removeHandler(i);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </label>
            </div>
          );
        })}
        <div className={styles.addNewField} onClick={newFieldHandler}>
          <FontAwesomeIcon icon={faPlus} />
          Add a new user
        </div>
        <div
          className={`${styles.save} ${
            validateAllUserAddress() ? null : styles.inactive
          }`}
          onClick={saveHandler}
        >
          <FontAwesomeIcon icon={faSave} />
          Update
        </div>
      </div>
    </div>
  );
};

export default Admin;
