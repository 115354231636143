import create from "zustand";

const contentStore = create((set, get) => ({
  // this is the default state of the application
  buildID: "",
  fields: [],
  fieldChanged: false,
  setBuildID: (buildID) => {
    set({buildID: buildID});
  },
  setFields: (fields) => {
    set({fields: fields});
  },
  updateFields: (fieldIndex, type, value) => {
    const fieldsClone = [...get().fields];
    fieldsClone[fieldIndex][type] = value;
    set({fields: fieldsClone});
    set({fieldChanged: true});
  },
  addNewField: () => {
    const fieldsClone = [...get().fields];
    fieldsClone.push({target: "", value: ""});
    set({fields: fieldsClone});
    set({fieldChanged: true});
  },
  removeField: (index) => {
    const fieldsClone = [...get().fields];
    fieldsClone.splice(index, 1);
    set({fields: fieldsClone});
    set({fieldChanged: true});
  },
  resetFieldChanged: () => {
    set({fieldChanged: false});
  }
}));

export default contentStore;
