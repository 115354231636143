import create from "zustand";

const userStore = create((set, get) => ({
  // this is the default state of the application
  userInfo: {
    displayName: "",
    email: "",
    photoURL: "",
    loggedIn: null
  },
  externalUsers: [],
  setUserInfo: (useInfo) => {
    set({userInfo: useInfo});
  },
  setLogOut: () => {
    set({
      userInfo: {displayName: "", email: "", photoURL: "", loggedIn: false}
    });
  },
  setExternalUsers: (adminUsers) => {
    set({externalUsers: adminUsers});
  },
  addEmptyNewUser: () => {
    const externalUsersClone = [...get().externalUsers];
    externalUsersClone.push("");
    set({externalUsers: externalUsersClone});
  },
  updateUser: (index, value) => {
    const externalUsersClone = [...get().externalUsers];
    externalUsersClone[index] = value;
    set({externalUsers: externalUsersClone});
  },
  removeUser: (index) => {
    const externalUsersClone = [...get().externalUsers];
    externalUsersClone.splice(index, 1);
    set({externalUsers: externalUsersClone});
  }
}));

export default userStore;
