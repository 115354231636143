import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import styles from "./App.module.scss";
import firebaseApp from "./core/firestore";
import userStore from "./store/userStore";
import LoginScreen from "./components/LoginScreen/LoginScreen";
import LogOut from "./components/LogOut/LogOut";
import BuildIDInput from "./components/BuildIDInput/BuildIDInput";
import FieldListWithRouter from "./components/FieldList/FieldList";
import Notification from "./components/Notification/Notification";
import Header from "./components/Header/Header";

const App = () => {
  const userInfo = userStore((state) => state.userInfo);
  const setUserInfo = userStore((state) => state.setUserInfo);
  useEffect(() => {
    const unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUserInfo({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL ? user.photoURL : "",
            loggedIn: true
          });
        } else {
          setUserInfo({
            displayName: "",
            email: "",
            photoURL: "",
            loggedIn: false
          });
        }
      });
    return () => {
      unregisterAuthObserver();
    };
  }, [setUserInfo]);
  return (
    <div className={styles.app}>
      <Header />
      <Router>
        <Switch>
          <Route exact path='/'>
            {userInfo.displayName !== "" && userInfo.email !== "" ? (
              <div className={styles.content}>
                <BuildIDInput />
                <LogOut />
                <Notification />
              </div>
            ) : (
              <LoginScreen />
            )}
          </Route>
          <Route
            exact
            path='/:buildID'
            children={
              userInfo.displayName !== "" && userInfo.email !== "" ? (
                <div className={styles.content}>
                  <FieldListWithRouter />
                  <LogOut />
                </div>
              ) : (
                <LoginScreen />
              )
            }
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
