import React from 'react';
import firebase from '../../core/firestore';

import userStore from '../../store/userStore';

import styles from './LogOut.module.scss';

const LogOut = () => {
  const setLogOut = userStore((state) => state.setLogOut);
  const userInfo = userStore((state) => state.userInfo);

  const logOutHandler = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        setLogOut();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div className={styles.logoutPanel}>
      <img
        src={`${
          userInfo.photoURL
            ? userInfo.photoURL
            : 'https://coenterprises.com.au/wp-content/uploads/2018/02/male-placeholder-image.jpeg'
        }`}
        alt="avatar"
      />
      <div className={styles.logout} onClick={logOutHandler}>
        Logout
      </div>
    </div>
  );
};

export default LogOut;
