import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styles from "./FieldList.module.scss";
import contentStore from "../../store/contentStore";
import userStore from "../../store/userStore";
import UIStore from "../../store/UIStore";
import firebase from "../../core/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faExternalLinkAlt,
  faPlus,
  faSave,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import Notification from "../Notification/Notification";
import Admin from "../Admin/Admin";

import _ from "lodash";
import { purgeBuildCache } from '../../services/ads-api';

const FieldList = (props) => {
  const userInfo = userStore((state) => state.userInfo);
  const buildID = contentStore((state) => state.buildID);
  const setBuildID = contentStore((state) => state.setBuildID);
  const fields = contentStore((state) => state.fields);
  const fieldChanged = contentStore((state) => state.fieldChanged);
  const setFields = contentStore((state) => state.setFields);
  const updateFields = contentStore((state) => state.updateFields);
  const addNewField = contentStore((state) => state.addNewField);
  const removeField = contentStore((state) => state.removeField);
  const resetFieldChanged = contentStore((state) => state.resetFieldChanged);
  const setNotificationMessage = UIStore(
    (state) => state.setNotificationMessage
  );
  const externalUsers = userStore((state) => state.externalUsers);
  const setExternalUsers = userStore((state) => state.setExternalUsers);
  const [lastUpdated, setLastUpdated] = useState({});
  const [cacheStatus, setCacheStatus] = useState('');

  useEffect(() => {
    if (props.match.params.buildID !== "") {
      setBuildID(props.match.params.buildID);
      const db = firebase.firestore();
      const dataRef = db
        .collection("Creatives")
        .doc(props.match.params.buildID);
      dataRef.get().then((doc) => {
        const data = doc.data();
        if (data) {
          if (data.fields) setFields(data.fields);
          if (data.externalUsers) setExternalUsers(data.externalUsers);
          if (data.lastUpdated) {
            setLastUpdated(data.lastUpdated);
          }
        } else {
          setNotificationMessage(
            "No creative found, adding new field to get started!"
          );
        }
      });
    }
    return () => {
      //   cleanup;
    };
  }, [
    props.match.params.buildID,
    setBuildID,
    setExternalUsers,
    setFields,
    setNotificationMessage
  ]);

  const validateFieldInput = () => {
    const emptyFields = [];
    fields.forEach((field) => {
      if (field.target === "" || field.value === "") {
        emptyFields.push(field);
      }
    });
    return emptyFields;
  };

  const changeHandler = (e, fieldIndex, type) => {
    const value = e.target.value;
    updateFields(fieldIndex, type, value);
  };

  const removeHandler = (index) => {
    removeField(index);
  };

  const newFieldHandler = () => {
    addNewField();
  };

  const purgeCache = async () => {
    setCacheStatus('Purging cache...');
    const success = await purgeBuildCache(buildID);
    if (success) {
      setCacheStatus('Cache purged!');
    } else {
      setCacheStatus('Error purging cache');
    }
  };

  const saveHandler = async (e) => {
    if (validateFieldInput().length === 0) {
      const db = firebase.firestore();
      const dataRef = db.collection("Creatives").doc(buildID);

      const geoAPI =
        "https://api.ipdata.co?api-key=ede8bee45b3877101765285019c64dd7d3fcc23def77dbe0a0a36202";

      const settings = {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      };

      const geoInfo = await fetch(geoAPI, settings)
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          return err;
        });

      const newLastUpdate = {
        date: Date.now(),
        user: userInfo.email,
        geo: geoInfo
      };
      setLastUpdated(newLastUpdate);
      dataRef
        .set({ fields: fields, lastUpdated: newLastUpdate }, { merge: true })
        .then(() => {
          purgeCache();
          setNotificationMessage("Creative successfully updated!");
          resetFieldChanged();
        });
    } else {
      setNotificationMessage("No empty field is allowed");
    }
  };

  const renderFields = () => {
    return (
      <div
        className={styles.fieldsWrapper}
        style={{
          marginRight: userInfo.email.includes("@playgroundxyz.com")
            ? "400px"
            : "0"
        }}
      >
        <div className={styles.buildID}>
          <Link className={styles.return} to='/'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <br />
          Creative Build ID: {buildID}
          <br />
          <a
            className={styles.viewCreative}
            target='_blank'
            href={`https://studio.playgroundxyz.com/${buildID}`}
            rel='noreferrer'
          >
            View Creative <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>
        <div className={styles.fields}>
          {fields.map((field, index) => (
            <div className={styles.field} key={index}>
              <label>
                <p>Target DOM ID</p>
                <span>#</span>
                <input
                  type='text'
                  value={field.target}
                  onChange={(e) => changeHandler(e, index, "target")}
                ></input>
              </label>
              <label>
                <p>Value</p>
                <input
                  type='text'
                  value={field.value}
                  onChange={(e) => changeHandler(e, index, "value")}
                ></input>
              </label>
              <div
                className={styles.delete}
                onClick={() => {
                  removeHandler(index);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.addNewField} onClick={newFieldHandler}>
          <FontAwesomeIcon icon={faPlus} />
          Add a new field
        </div>
        <div
          className={`${styles.save} ${fieldChanged ? styles.active : styles.inactive
            }`}
          onClick={fieldChanged ? saveHandler : null}
        >
          <FontAwesomeIcon icon={faSave} />
          Save
        </div>
        {_.isEmpty(
          { lastUpdated } &&
          lastUpdated.geo &&
          lastUpdated.date &&
          lastUpdated.geo.city &&
          lastUpdated.geo.region_code
        ) ? null : (
          <div className={styles.lastUpdated}>
            <p>Last Updated by: {lastUpdated.user}</p>
            <p>
              on {new Date(lastUpdated.date).toLocaleDateString()} at{" "}
              {new Date(lastUpdated.date).toLocaleTimeString()}
            </p>
            <p>
              in {lastUpdated.geo.city}, {lastUpdated.geo.region_code}
            </p>
            <p>{cacheStatus}</p>
          </div>
        )}
        <Notification />
      </div>
    );
  };
  return (
    <div className={styles.fieldList}>
      {(externalUsers.length && externalUsers.includes(userInfo.email)) ||
        userInfo.email.includes("@playgroundxyz.com") ? (
        renderFields()
      ) : (
        <div className={styles.permission}>
          <h1>Insufficient Permission</h1>
          <p>Please contact us to set up.</p>
        </div>
      )}
      {userInfo.email.includes("@playgroundxyz.com") ? <Admin /> : null}
    </div>
  );
};

const FieldListWithRouter = withRouter(FieldList);

export default FieldListWithRouter;
