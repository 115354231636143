import create from 'zustand';

const UIStore = create((set, get) => ({
  // this is the default state of the application
  notificationMessage: '',
  setNotificationMessage: (message) => {
    set({ notificationMessage: message });
    setTimeout(() => {
      set({ notificationMessage: '' });
    }, 5000);
  },
  clearNotificationMessage: () => {
    set({ notificationMessage: '' });
  }
}));

export default UIStore;
